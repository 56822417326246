export function typeText(elementId, fullText, baseTextLength, pause = 2000, speed = 120) {
  const target = document.getElementById(elementId);
  let index = baseTextLength;
  let isAdding = true;

  async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function type() {
    while (true) { // Continuous loop
      await sleep(speed);
      if (isAdding) {
        if (index > fullText.length) {
          await sleep(pause); // wait at the end before backspacing
          isAdding = false;
        } else {
          target.innerText = fullText.slice(baseTextLength, index); // only update the dynamic part
          index++;
        }
      } else {
        if (index === baseTextLength) {
          isAdding = true; // switch to adding after deletion completes
        } else {
          target.innerText = fullText.slice(baseTextLength, index); // maintain the base text
          index--;
        }
      }
    }
  }

  type(); 
}
