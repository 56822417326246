import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';


const containerStyle = {
  width: '900px',
  height: '500px'
};

const center = {
  lat: 40.382209, 
  lng: -79.9959699  
};


const libraries = ["places"];

const MapComponent = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC3radCHS5xrzrCXoJQEM_SDWhPIvrzxhE",
    libraries
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }
 
  if (!isLoaded) {
    return <div>Loading Maps...</div>;  
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
    >
    <Marker 
        position={center}
     
      />

    </GoogleMap>
  );
}

export default MapComponent;
