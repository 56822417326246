import React from 'react'
import MapComponent from './MapComponent'
import pin2 from "../images/pin2.png"
import banner from "../images/banner.jpg"

const Location = () => {
  return (
<div>
    <img className='banner' src={banner} alt='Banner' />
    <div className="location">
      <div className='location-container'>
        <div className='address'>
          <img src={pin2} alt = "pin" />    
          <h4>BARBER SHOP STYLES ®</h4>
          <p> 4114 Saw Mill Run Boulevard</p>
          <p> Pittsburgh, PA 15227-2610</p>
        </div>
        <div className='apt hide'>
          <h6>Call for an Appointment</h6>
          <p> (412) 207-8252</p> 
          <p> info@barbershopstyles.com</p> 
        </div>
      </div>
      <div className='map'>
      <MapComponent />
      </div>
    </div></div>
  )
}

export default Location