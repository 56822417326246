import React, {useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faScissors, faWrench, faGavel } from '@fortawesome/free-solid-svg-icons';
import { faBlackTie } from '@fortawesome/free-brands-svg-icons';
import banner from "../images/banner.jpg"

const Services = () => {

  const servicesRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    const adjustLine = () => {
      if (servicesRef.current && lineRef.current) {
        const serviceItems = servicesRef.current.getElementsByClassName('service-item');
        const firstIcon = serviceItems[0].querySelector('.icon-circle').getBoundingClientRect();
        const lastIcon = serviceItems[serviceItems.length - 1].querySelector('.icon-circle').getBoundingClientRect();
        const topPosition = firstIcon.top + firstIcon.height / 2;
        const bottomPosition = lastIcon.top + lastIcon.height / 2;
        lineRef.current.style.top = `${topPosition - servicesRef.current.getBoundingClientRect().top}px`;
        lineRef.current.style.height = `${bottomPosition - topPosition}px`;
      }
    };

    adjustLine();
    window.addEventListener('resize', adjustLine);

    return () => window.removeEventListener('resize', adjustLine);
  }, []);


  return (
    <div >
       <img className='banner' src={banner} alt='Banner' />
    <div className='services' ref={servicesRef}>
       <div className="service-item">
       <div className="icon-circle">
        <FontAwesomeIcon icon={faPerson} size="2x" /> </div>
        <div className="service-text">
          <h4>MEN'S AND BOYS' HAIRSTYLING</h4>
          <p>From the age of 2 on, men and boys can come in and have their hair professionally cut by any of our barbers, and enjoy the relaxing atmosphere of a traditional barber shop. Our haircuts include traditional styles, such as fades and military cuts, as well as modern styles.</p>
        </div>
      </div>
      <div className="service-item">
      <div className="icon-circle">
        <FontAwesomeIcon icon={faGavel} size="2x" /></div>
        <div className="service-text">
          <h4>STRAIGHT-RAZOR SHAVES</h4>
          <p>Modern safety razors do not compare to the closeness or relaxing feeling of a steady hand performing a traditional straight-razor shave. First, our barbers prepare the skin by applying a series of hot towels to the face, followed by a pre-shave facial scrub, and then more hot towels.<br/> <br/>
          A base shave cream is applied, and then a hot lather applied over it. An extremely sharp straight razor is used to remove unwanted hair, guided by the skillful hand of a professional barber. After all straight-razor shaves, another hot towel is used to remove shave cream and stubble. We then apply an astringent, followed by men's talc powder, to condition the skin.</p>
        </div>
      </div>
      <div className="service-item">
      <div className="icon-circle">
        <FontAwesomeIcon icon={faScissors} size="2x" /></div>
        <div className="service-text">
          <h4>BEARD AND MUSTACHE TRIMS</h4>
          <p>Our professional barbers will perform a shape-up of the beard and mustache according to your preference. First, the beard and mustache are cut down to your desired thickness. Then a straight razor lineup will give a pronounced and finished look.</p>
        </div>
      </div>
      <div className="service-item">
      <div className="icon-circle">
        <FontAwesomeIcon icon={faWrench} size="2x" /></div>
        <div className="service-text">
          <h4>TUNE-UP</h4>
          <p>The Tune-Up is a simple service that we are very well-known for. Our professional barbers will edge and shave your neck, side burns, and around the ears to keep you looking great between your usual haircuts.</p>
        </div>
      </div>
      <div className="service-item">
      <div className="icon-circle last">
        <FontAwesomeIcon icon={faBlackTie} size="2x" /> </div>
        <div className="service-text">
          <h4>TRADITIONAL STYLE</h4>
          <p>Just like our services, our barber shop is decorated in a traditional manner, right down to the striped barber pole. You'll find flat screen televisions playing sports along with cool background music to help you relax.</p>
        </div>
      </div>
      <div className="services-line" ref={lineRef} />
    </div>
 
    </div>
    
  )
}

export default Services;
