import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../images/logo.png";

const Navbar = () => {

  return (
    <div className="Header">  
      <img className='logo' src={logo} alt="Logo" />
      <nav>
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          <li style={{ display: 'inline', marginRight: 10 }}>
            <NavLink to="/" className={({ isActive }) => isActive ? 'active-link' : undefined}>
              Home
            </NavLink>
          </li>
          <li style={{ display: 'inline', marginRight: 10 }}>
            <NavLink to="/services" className={({ isActive }) => isActive ? 'active-link' : undefined}>
              Services
            </NavLink>
          </li>
          <li style={{ display: 'inline', marginRight: 10 }}>
            <NavLink to="/location" className={({ isActive }) => isActive ? 'active-link' : undefined}>
              Location
            </NavLink>
          </li>
          <li style={{ display: 'inline', marginRight: 10 }}>
            <NavLink to="/hours" className={({ isActive }) => isActive ? 'active-link' : undefined}>
              Hours
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
