import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarXmark, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import banner from "../images/banner.jpg"


const Hours = () => {
  return (
    <div>
    <img className='banner' src={banner} alt='Banner' />
    <div className='hours'>
      <div className='hours-schedule'>
        <div className="hour-item">
        <div className="icon-circle red-circle">
          <FontAwesomeIcon icon={faCalendarXmark} size="2x" /> </div>
          <p>SUNDAY: CLOSED</p>
        </div>
        <div className="hour-item">
        <div className="icon-circle red-circle">
          <FontAwesomeIcon icon={faCalendarXmark} size="2x" /> </div>
          <p>MONDAY: CLOSED</p>
        </div>
        <div className="hour-item">
        <div className="icon-circle white-circle">
          <FontAwesomeIcon icon={faCalendarPlus} size="2x" /> </div>
          <p>TUESDAY: 9:30AM-7PM</p>
        </div>
        <div className="hour-item">
        <div className="icon-circle white-circle">
          <FontAwesomeIcon icon={faCalendarPlus} size="2x" /> </div>
          <p>WEDNESDAY: 9:30AM-7PM</p>
        </div>
        <div className="hour-item">
        <div className="icon-circle white-circle">
          <FontAwesomeIcon icon={faCalendarPlus} size="2x" /> </div>
          <p>THURSDAY: 9:30AM-7PM</p>
        </div>
        <div className="hour-item">
        <div className="icon-circle white-circle">
          <FontAwesomeIcon icon={faCalendarPlus} size="2x" /> </div>
          <p>FRIDAY: 9:30AM-7PM</p>
        </div>
        <div className="hour-item">
        <div className="icon-circle blue-circle">
          <FontAwesomeIcon icon={faCalendarPlus} size="2x" /> </div>
          <p>SATURDAY: 9:00AM-3PM</p>
        </div>
      </div>
      <div className='apt-2'>
        <h6>Call for an Appointment</h6>
        <p>(412) 207-8252</p>
        <p>info@barbershopstyles.com</p>
      </div> 
    </div>
    </div>
  )
}

export default Hours;
