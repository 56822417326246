import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import credit_cards from '../images/credit_cards.png';
import service from '../images/service.png';
import hours from '../images/hours.png';
import location from '../images/location.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  const websiteUrl = encodeURIComponent('http://www.barbershopstyles.com');
  const shareMessage = encodeURIComponent("Check out Barber Shop Styles!");

  return (
    <div className='footer'>
      <div className='links'>
      <Link to="/services">   <img src={service} alt='services' /> </Link>
      <Link to="/location"> <img src={location} alt='location' /> </Link>
      <Link to="/hours">  <img src={hours} alt='hours' /> </Link>
      </div>
      <div className='footer-container'>
        <div className='share'>
          <div>Share:</div>
          <div className='share-icons'>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${websiteUrl}`} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
            </a>
            <a href={`https://twitter.com/intent/tweet?text=${shareMessage}&url=${websiteUrl}`} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
            </a>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${websiteUrl}&title=${shareMessage}`} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            </div>
            <div className="footer-flexbox">
            <div className='ccs'>
            <img src={credit_cards} alt='Credit Cards Accepted'/>
          </div>
          <div>
          <div className='info'>
            Barber Shop Styles ®<br/>
            4114 Saw Mill Run Boulevard<br/>
            Pittsburgh, PA 15227-2610<br/><br/>
            Phone: (412) 207-8252<br/>
            info@barbershopstyles.com
          </div>
          </div>
        </div>
          </div>
      </div>
      </div>
    )
  }

  export default Footer;
