import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faStar, faPhone, faScissors } from '@fortawesome/free-solid-svg-icons';
import hero from '../images/hero.png';
import { Link } from 'react-router-dom';
import { typeText } from '../typingEffect';
import award from "../images/award.jpg"


const Home = () => {
    useEffect(() => {
        typeText("typing", "OLD SCHOOL BARBERING MEETS THE 21ST CENTURY.", "OLD SCHOOL BARBERING".length);
    }, []);

    return (
        <div className='home'>
            <div className='typing'>
                <h1>OLD SCHOOL BARBERING<span id="typing"></span><span className="cursor">|</span></h1>
            </div>
            <div className='section-container'> 
                <div className='section-1'>
                    <a href="tel:1-412-207-8252"><FontAwesomeIcon icon={faPhone} size="2x" /></a>
                    <h4>PLEASE CALL FOR AN APPOINTMENT</h4>
                    <h4><a href="tel:1-412-207-8252">(412) 207-8252</a></h4>    
                </div>
                <div className='section-2'>
                    <div className='leftbox'>
                        <FontAwesomeIcon icon={faScissors} size="2x" />
                        <p>BARBER SHOP STYLES ® LOCATED IN PITTSBURGH, PENNSYLVANIA, BARBER SHOP STYLES ® IS HOME TO PROFESSIONAL BARBERS SPECIALIZING IN MEN'S AND BOYS' HAIRCUTS AND STYLES, INCLUDING MILITARY CUTS, FADES, AND STRAIGHT-RAZOR SHAVES. YOU CAN ALSO ENJOY PROFESSIONAL BEARD AND MUSTACHE TRIMS WHILE YOU RELAX AND ENJOY CLASSIC BARBER SERVICES.</p>
                    </div>
                    <div className='expertise'>
                        <a href="https://www.expertise.com/lifestyle/barbershops/pennsylvania/pittsburgh" target="_blank" rel="noreferrer"><img className="award" src={award} alt="expertise.com" /></a>
                    </div>
                </div>
            </div>
            <div className='section-3'>
                <div className="flip-card">
                    <div className="flip-card-container">
                        <div className="flip-card-inner">
                            <div className="flip-card-front red-background">
                                <FontAwesomeIcon icon={faTrophy} size = "2x" />
                                <h3>PROFESSIONAL, TRADITIONAL SERVICES</h3>
                                <p>Our barbers are professionally licensed and provide traditional barbering services for men who want to enjoy a more masculine environment than modern hairstylists and salons.</p>
                            </div>
                            <div className="flip-card-back red-background">
                                <h3>BARBER SHOP STYLES</h3>
                                <p>Schedule an appointment for professional barbering services from our local shop.</p>
                                <a className="button button-2" href="tel:1-412-207-8252">CALL TODAY</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-container">
                        <div className="flip-card-inner">
                            <div className="flip-card-front blue-background">
                                <FontAwesomeIcon icon={faStar} size="2x" />
                                <h3>CUSTOMER SERVICE IS OUR TOP PRIORITY</h3>
                                <p>At Barber Shop Styles®, our highest priority is to provide the services that will keep you coming back for more. Our philosophy is that we let you kick back and relax while our professional barbers take care of the details, ensuring that you look your best wherever you go.</p>
                            </div>
                            <div className="flip-card-back blue-background">
                                <h3>SERVICES</h3>
                                <p>Whether you're looking to get ready for a special occasion, or you just need to keep your hairstyle fresh, count on us to provide the services you need. Our top barbers will keep you looking great visit after visit.</p>
                                <Link to="/services" className="button">MORE INFO</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section-4'>
                <img src={hero} alt='steel city hero hunts' />
                <h4>Barber Shop Styles ® is Proud of our Veterans and is an Official Sponsor of Steel City Hero Hunts</h4>
            </div>
        </div>
  );
}

export default Home;
